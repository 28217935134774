import { RevieveAR } from '../../../src/RevieveAR';

require('popper.js');
require('bootstrap/dist/css/bootstrap.min.css');
require('bootstrap/dist/js/bootstrap.min.js');
require('bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css');
require('bootstrap-colorpicker/dist/js/bootstrap-colorpicker.min.js');
require('../css/simple-sidebar.css');
require('../css/test.css');

let configuration = {
  partnerID: 'XxDi4W65d2',
  skintone: 1,
};
let revieveAR;
let imageFile;
let initialized = false;
let effectFunctions = {};

window.onLoadActions = () => {
  $('#modalFile').modal('toggle');
  loadOptionsIntoSelects();
  $('#applyLipstickColor').colorpicker();
  $('#applyBlushColor').colorpicker();
};

let initializeRevieveAR = () => {
  revieveAR = new RevieveAR(configuration, imageFile, 'afterContainer');
  $('#modalLoading').modal('show');
  revieveAR.initialize().then(() => {
    $('#modalLoading').modal('hide');
    if (!initialized) {
      $('#wrapper').toggleClass('toggled');
      initialized = true;
    } else {
      $('#beforeContainer')[0].removeChild($('#beforeContainer')[0].firstChild);
      if ($('#afterContainer')[0].firstChild) {
        $('#afterContainer')[0].removeChild($('#afterContainer')[0].firstChild);
      }
      applyEffects();
    }
    let beforeImage = document.createElement('img');
    beforeImage.src = revieveAR.getImageBefore();
    $('#beforeContainer')[0].appendChild(beforeImage);
    // we need to check if there are some warnings in the image analysis
    while ($('#warningList')[0].firstChild) {
      $('#warningList')[0].removeChild($('#warningList')[0].firstChild);
    }
    let statusResults = revieveAR.getStatus();
    if (statusResults && statusResults.length > 0) {
      for (const statusElement of statusResults) {
        let liObject = document.createElement('li');
        liObject.innerHTML = statusElement.description;
        $('#warningList')[0].appendChild(liObject);
      }
      $('#modalWarnings').modal('toggle');
    }
  }).catch((error) => {
    if (error) {
      console.error(error);
    }
    $('#modalLoading').modal('hide');
    $('#modalError').modal('show');
  });
};

$('#acceptWarningsButton').click(function (e) {
  e.preventDefault();
  $('#modalWarnings').modal('toggle');
});

let applyEffects = () => {
  for (const effectActive of $('.effectApplied')) {
    let effectFunction = effectActive.id.replace('Button', '');
    effectFunctions[effectFunction]();
  }
};

$('#menu-select-file').click(function (e) {
  e.preventDefault();
  $('#modalFile').modal('toggle');
});

let loadOptionsIntoSelects = () => {
  let selects = ['#reduceRednessMasks', '#reduceWrinklesMasks', '#brightenSkinMasks'];
  for (let select of selects) {
    for (let mask in RevieveAR.masks) {
      if (RevieveAR.masks[mask]) {
        $(select).append($('<option>', {
          value: RevieveAR.masks[mask],
          text: RevieveAR.masks[mask].toUpperCase(),
        }));
      }
    }
  }
};

$('#effectsMenuButton').click(function (e) {
  e.preventDefault();
  $('#wrapper').toggleClass('toggled');
});

$('#inputFile').change(function (e) {
  $('#modalFile').modal('toggle');
  $('#modalLoading').modal('toggle');
  e.preventDefault();
  let file = $('#inputFile')[0].files[0];
  if (file) {
    imageFile = file;
    $('#modalLoading').modal('hide');
    $('#modalSkintone').modal('toggle');
    // if you would like to convert the file into base64 value, you can use this code
    // let reader = new FileReader();
    // reader.addEventListener('load', function () {
    //   $('#modalLoading').modal('hide');
    //   imageFile = reader.result;
    //   $('#modalSkintone').modal('toggle');
    // }, false);
    // reader.readAsDataURL(file);
  }
});

$('#startEffects').click(function (e) {
  e.preventDefault();
  let skintone = $('#selectSkintone')[0].selectedOptions[0].value;
  configuration.skintone = skintone;
  $('#modalSkintone').modal('toggle');
  initializeRevieveAR();
});

let reduceEyebags = () => {
  let strenght = $('#reduceEyebagsStrenght')[0].value / 100;
  revieveAR.reduceEyebags(strenght).then(() => {
    if (strenght > 0) {
      $('#reduceEyebagsButton').addClass('effectApplied');
    } else {
      $('#reduceEyebagsButton').removeClass('effectApplied');
    }
  });
};

effectFunctions.reduceEyebags = reduceEyebags;

$('#reduceEyebagsStrenght').change(function (e) {
  e.preventDefault();
  reduceEyebags();
});

let reduceCrowsFeet = () => {
  let strenght = $('#reduceCrowsFeetStrenght')[0].value / 100;
  revieveAR.reduceCrowsFeet(strenght).then(() => {
    if (strenght > 0) {
      $('#reduceCrowsFeetButton').addClass('effectApplied');
    } else {
      $('#reduceCrowsFeetButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.reduceCrowsFeet = reduceCrowsFeet;

$('#reduceCrowsFeetStrenght').change(function (e) {
  e.preventDefault();
  reduceCrowsFeet();
});

let reduceDarkcircles = () => {
  let strenght = $('#reduceDarkcirclesStrenght')[0].value / 100;
  revieveAR.reduceDarkcircles(strenght).then(() => {
    if (strenght > 0) {
      $('#reduceDarkcirclesButton').addClass('effectApplied');
    } else {
      $('#reduceDarkcirclesButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.reduceDarkcircles = reduceDarkcircles;

$('#reduceDarkcirclesStrenght').change(function (e) {
  e.preventDefault();
  reduceDarkcircles();
});

let reduceRedness = () => {
  if ($('#reduceRednessMasks')[0].selectedOptions.length <= 0) {
    alert('Please, select at least one area to apply the effect');
    return;
  }
  let selectedMasks = [];
  for (let option of $('#reduceRednessMasks')[0].selectedOptions) {
    selectedMasks.push(option.value);
  }
  let strenght = $('#reduceRednessStrenght')[0].value / 100;
  revieveAR.reduceRedness(strenght, selectedMasks).then(() => {
    if (strenght > 0) {
      $('#reduceRednessButton').addClass('effectApplied');
    } else {
      $('#reduceRednessButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.reduceRedness = reduceRedness;

$('#reduceRednessApply').click((e) => {
  e.preventDefault();
  reduceRedness();
});

$('#reduceRednessStrenght').change((e) => {
  e.preventDefault();
  reduceRedness();
});

let reduceWrinkles = () => {
  if ($('#reduceWrinklesMasks')[0].selectedOptions.length <= 0) {
    alert('Please, select at least one area to apply the effect');
    return;
  }
  let selectedMasks = [];
  for (let option of $('#reduceWrinklesMasks')[0].selectedOptions) {
    selectedMasks.push(option.value);
  }
  let strenght = $('#reduceWrinklesStrenght')[0].value / 100;
  revieveAR.reduceWrinkles(strenght, selectedMasks).then(() => {
    if (strenght > 0) {
      $('#reduceWrinklesButton').addClass('effectApplied');
    } else {
      $('#reduceWrinklesButton').removeClass('effectApplied');
    }
  });
};

effectFunctions.reduceWrinkles = reduceWrinkles;

$('#reduceWrinklesApply').click((e) => {
  e.preventDefault();
  reduceWrinkles();
});

$('#reduceWrinklesStrenght').change((e) => {
  e.preventDefault();
  reduceWrinkles();
});

let brightenSkin = () => {
  if ($('#brightenSkinMasks')[0].selectedOptions.length <= 0) {
    alert('Please, select at least one area to apply the effect');
    return;
  }
  let selectedMasks = [];
  for (let option of $('#brightenSkinMasks')[0].selectedOptions) {
    selectedMasks.push(option.value);
  }
  let strenght = $('#brightenSkinStrenght')[0].value / 100;
  revieveAR.brightenSkin(strenght, selectedMasks).then(() => {
    if (strenght > 0) {
      $('#brightenSkinButton').addClass('effectApplied');
    } else {
      $('#brightenSkinButton').removeClass('effectApplied');
    }
  });
};

effectFunctions.brightenSkin = brightenSkin;

$('#brightenSkinApply').click((e) => {
  e.preventDefault();
  brightenSkin();
});

$('#brightenSkinStrenght').change((e) => {
  e.preventDefault();
  brightenSkin();
});

let applyLipstick = () => {
  let color = $('#applyLipstickColor')[0].value;
  if (!color) {
    alert('Please, select the lipstick color you want to apply');
    return;
  }
  let strenght = $('#applyLipstickStrenght')[0].value / 100;
  revieveAR.applyLipstick(strenght, color).then(() => {
    if (strenght > 0) {
      $('#applyLipstickButton').addClass('effectApplied');
    } else {
      $('#applyLipstickButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.applyLipstick = applyLipstick;

$('#applyLipstickApply').click((e) => {
  e.preventDefault();
  applyLipstick();
});
$('#applyLipstickStrenght').change((e) => {
  e.preventDefault();
  applyLipstick();
});

let applyBlush = () => {
  let color = $('#applyBlushColor')[0].value;
  if (!color) {
    alert('Please, select the blush color you want to apply');
    return;
  }
  let strenght = $('#applyBlushStrenght')[0].value / 100;
  revieveAR.applyBlush(strenght, color).then(() => {
    if (strenght > 0) {
      $('#applyBlushButton').addClass('effectApplied');
    } else {
      $('#applyBlushButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.applyBlush = applyBlush;

$('#applyBlushApply').click((e) => {
  e.preventDefault();
  applyBlush();
});

$('#applyBlushStrenght').change((e) => {
  e.preventDefault();
  applyBlush();
});

let applyFoundation = () => {
  let strenght = $('#applyFoundationStrenght')[0].value / 100;
  revieveAR.applyFoundation(strenght).then(() => {
    if (strenght > 0) {
      $('#applyFoundationButton').addClass('effectApplied');
    } else {
      $('#applyFoundationButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.applyFoundation = applyFoundation;

$('#applyFoundationStrenght').change(function (e) {
  e.preventDefault();
  applyFoundation();
});

$('#selectNewPicture').click(function (e) {
  e.preventDefault();
  $('#modalFile').modal('toggle');
});
